h2.css-1pwe92q{
    font-weight: bold;
}
.search-filter label{
    font-size: 0.9rem ;
}
.postalrangesearch{
    padding-bottom: 1px;    
    .e1atnqi30{
        font-size: 0.9rem;
    }
}

.enhed {
    margin-bottom: 0.25rem;
    border-bottom: 1px solid #005a8d;
}

.entityName {
    margin-top: 0.25rem;
    color: #385c6b;
    font-size: 1.2rem;
    padding-bottom: 0;
    font-weight: bold;
}

.notvisible {
    display: none;
}

.multilistsearch{
    ul{
        max-height: 120px;
    }
    label{
        font-size: 0.7rem;
        
      span{
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        @media screen and(min-width: 768px) {
            max-width: 85%;
            
        }
        @media screen and(min-width: 1024px) {
            max-width: 90%;
            
        }

      }
    }
    @media screen and(min-width: 960px) {
        
        label{
            font-size: 0.9rem;
        }
    }
  }
  .singledatalist{
      ul{
          padding-bottom: 0;
      }
      li{
          height: auto !important;
      }
  }
  .selected-filters{
      min-height: 35px;
  }
  .filters-toggle{
      display: none;
      @media screen and(min-width:768px) {
          display: block;
          .btn-outline-light{
              color:#343a40;
          }
      }
  }
body {
    font-family: Arial, Helvetica, sans-serif;
}

.search-header {
    min-height: 94px;
}

.logo-container {
    position: absolute;
    top: 6px;
    left: 15px;
}

.logo {
    width: 110px;
    height: auto;
}

.logo-text {
    display: none;
}

.nav-link--large {
    min-width: 178px;
}

.nav {
    position: sticky;
    top: 165px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    height: 75vh;
}

.freetext-search {
    //icons
    .css-c0lsh8, .css-1nawaly {
        top: calc(50% - 12px);
    }
}

.search-results label {
    margin-right: 0.25rem;
}

.result-list--detail label {
    font-weight: bold;
    margin-right: 0.25rem;
}

.freesearch.sort-options {
    background-color: white;
    color: black;
    font-size: 0.90rem;
    outline: none;
    border: none;
    height: auto;
    padding: 5px 1rem;
    border: 1px solid rgb(52, 58, 64);
}

mark {
    background-color: yellow;
}

.navbarHeader {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    top: 95px;
    z-index: 1000002;
}

.result-list--detail {
    position: -webkit-sticky;
    position: sticky;
    top: 165px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    height: 75vh;
}

.filter-list--detail {
    position: -webkit-sticky;
    position: sticky;
    top: 165px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    height: 75vh;
}

@media screen and (max-height: 815px) {
    .result-list--detail {
        height: 75vh;
        overflow-y: auto;
        overflow-x: hidden;
    }
}

.result-list--detail label {
    margin-top: 0.5rem;
    margin-bottom: 0.25rem;
}

.result-list--detail a {
    padding: 0;
}

.result-list--detail__title {
    font-weight: 500;
    font-size: 1.2rem;
}

.search-header {
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000003;
    padding-bottom: 1px;
}

.search-header {
    background-color: #f6f5f0;
    background-size: cover;
    //position: relative;
}

.search-header__searchfield {
    max-width: 400px;
    width: 100%;
    margin: 5rem 0 0.5rem 0;
}

.search-header__searchfield h2 {
    color: #005a8d;
    font-size: 1rem;
    text-align: center;
    margin-bottom: 0.5rem;
}

.search-body a:link {
    text-decoration: none;
}

.search-body .search-results__title {
    color: #385c6b;
    font-size: 1.2rem;
    padding-bottom: 0;
}

.search-results__button-row {
    margin: 0.5rem 0;
}

.navbar {
    background-color: #f2f4f6;
}

.navbar-nav {
    margin: 0 auto;
}

.navbar a:link,
.navbar a {
    color: #000;
    text-decoration: none;
}

.navbar .nav-link.is-active {
    border-bottom: 1px solid #bfe600;
    font-weight: bold;
}


.navbar-text--white {
    color: #fff;
    background-color: #005a8d;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 1rem;
    font-weight: 500;
}

.navbar-text--black {
    color: #000;
    background-color: #f2f4f6;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 1rem;
    font-weight: 500;
}

.navbar__details-header {
    display: none;
}

#navbarSupportedContent.show {
    background-color: #eef1f4;
}

.DayPickerInput input {
    height: 48px !important;
}

//mark selected item in freesearch list
.sor-selected {
    background-color: #eee !important;
}

.sor-not-selected {
    cursor: pointer;
}


@media (min-width: 768px) {
    .logo {
       width: 149px;
    }

    .search-header__searchfield {
        max-width: 425px;
        width: 100%;
        margin: 0.5rem 0 0.5rem 0;
    }

    .search-header__searchfield h2 {
        font-size: 1.1rem;
    }
}

@media (min-width: 960px) {
    .search-body .search-results__btn {
        padding: 0.375rem;
    }

    .nav-item {
        text-align: center;
    }

    .search-header__searchfield {
        max-width: 600px;
        margin-left: 0;
    }

    .search-header__searchfield h2 {
        font-size: 1.5rem;
    }

    .logo-container {
        top: 15px;
    }

    .search-results__button-row {
        margin: 0;
    }
}

@media (min-width: 1280px) {
    .navbar__details-header {
        display: inline-block;
    }

    .navbar__details-header--mobile {
        display: none;
    }
}

/*

Custom dropdown for filters on search page

*/

.sor-dropdown {
    position: relative;
}

.sor-screen-cover {
    position: fixed;
    background-color: white;
    opacity: 0;
    width: 100%;
    top: 0;
    bottom: 0;
    z-index: 999;
}

/*

Internet Explorer 11 specifics

*/

[isIE11=true] {
    .freesearch.sort-options {
        // a background image signifying a UI element is not working properly in IE11,
        // as the browser wants to display its own graphic.
        background-image: none;
    }

    .cancel-icon {
        width: 20px;
    }

    #FreetextSearch-downshift-input {
        // https://stackoverflow.com/questions/14007655/remove-ie10s-clear-field-x-button-on-certain-inputs
        &::-ms-clear {
            display: none;
        }
    }

    @media only screen and (max-width: 1280px) {
        .navbarHeader {
           color: red !important; 
        }

        .result-list--detail {
            display: block;
            position: relative;
            top: 165px;
            overflow-y: auto;
            overflow-x: hidden;
            scrollbar-width: thin;
            height: 75vh;
            width: 100%;
            right: 0px;
            z-index: 100000;
            background: white;
        }

        .top-seach-tree {
            top: 165px;
        }

        .navbar__details-header--mobile {
            top: 165px;
            z-index: 100000;
        }
        
    } 

    @media only screen and (min-width: 1280px) {
        .result-list--detail {
            display: block;
            position: fixed;
            top: 165px;
            overflow-y: auto;
            overflow-x: hidden;
            scrollbar-width: thin;
            height: 75vh;
            width: 24%;
            right: 0px;
        }
    }

    .navbarHeader {
        position: fixed;
        top: 0;
        width: 100%;
        top: 95px;
        z-index: 1000002;
    }

    .search-header {
        justify-content: center;
        position: fixed;
        top: 0;
        z-index: 1000003;
        padding-bottom: 1px;
        width: 100%;
    }


    .filter-list--detail {
        position: fixed;
        top: 165px;
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-width: thin;
        height: 75vh;
        width: 24%;
    }

    .top-seach-tree {
        top: 165px;
    }

    .nav {
        position: fixed;
        top: 165px;
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-width: thin;
        height: 75vh;
    }

    .tab-pane {
        margin-top: 160px;
    }
}

/* The Modal (background) */
.history-modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.history-modal-content {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 70%; /* Could be more or less, depending on screen size */
    font-size: 0.80rem;
}

/* The Close Button */
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.custom-select:focus {
    border-color: black !important;
    box-shadow: 0 0 0 0.2rem lightblue !important;
}
.custom-select:hover {
    border-color: black !important;
}

$tree_link--md: 66%;
$tree_link--xxl: 50%;
$tree__link--gutter: 13px;
.search-results--tree{
    li{
        list-style-type: none;
        position: relative;
    }
    .caret-right{
        padding-right: 1rem;
        display: none;
    }
    .collapsed .caret-right{
        display: inline;
    }
    .caret-down{
        padding-right: 1rem;
        display: inline;
        margin-left: -4px;
    }
    .circle{        
        position: absolute;
        left: -24px;        
    }
    
    .svg-inline--fa.fa-circle{
        width: 0.5rem;
        color: #bfe600;
    }
    .collapsed .caret-down,
    .collapsed .circle{
        display: none;
    }
    .search-result--tree__inner-wrapper{
        display: inline-block;
        width: 100%;
    }
    .search-result--tree__inner-wrapper[data-selected="sor-selected"]{        
            font-weight: bold;        
    }
    
    .search-result--tree__circle-1{
        font-size: 1rem;
       
        a:link{
            color:#005a8d;
        }
    }
    .search-result--tree__circle .search-result--tree__entityname{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-block;
        width: 90%;
        vertical-align: middle;
    }
    .search-result--tree__circle-2{
        font-size: 1rem;
        @media screen and(min-width:768px) {
            a{
                display: inline-block;
                width: calc(#{$tree_link--md} - #{$tree__link--gutter});
            }    
        }
        @media screen and(min-width:1440px) {
            a{
                width: calc(#{$tree_link--xxl} - #{$tree__link--gutter});
            }
        }
        .circle{
            left: -32px;
        }
        .caret-down{
            margin-left: -4px;
        }
    }
    .search-result--tree__circle-3{
        font-size: 1rem;
        @media screen and(min-width:768px) {
            a{
                display: inline-block;
                width: calc(#{$tree_link--md} - #{$tree__link--gutter*2});
            }
        }
        @media screen and(min-width:1440px) {
            a{
                width: calc(#{$tree_link--xxl} - #{$tree__link--gutter+20px});
            }
        }
        .circle{
            left: -40px;
        }        
    }
    .search-result--tree__circle-4{
        font-size: 1rem;
        @media screen and(min-width:768px) {
            a{
                display: inline-block;
                width: calc(#{$tree_link--md} - #{$tree__link--gutter*3});
            }
        }
        @media screen and(min-width:1440px) {
            a{
                width: calc(#{$tree_link--xxl} - #{$tree__link--gutter+40px});
            }
        }
        .circle{
            left: -48px;
        }
        
        
    }
    .search-result--tree__circle-5{
        font-size: 1rem;
        @media screen and(min-width:768px) {
            a{
                display: inline-block;
                width: calc(#{$tree_link--md} - #{$tree__link--gutter*3});
            }
        }
        @media screen and(min-width:1440px) {
            a{
                width: calc(#{$tree_link--xxl} - #{$tree__link--gutter+60px});
            }
        }
        .circle{
            left: -56px;
        }        
    }
    .search-result--tree__circle-6{                  
        @media screen and(min-width:768px) {
            a{
                display: inline-block;
                width: calc(#{$tree_link--md} - #{$tree__link--gutter*4});
            }
        }
        @media screen and(min-width:1440px) {
            a{
                width: calc(#{$tree_link--xxl} - #{$tree__link--gutter+80px});
            }
        }
        .circle{
            left:-64px;
        }
    }
    .search-result--tree__circle-7{
        @media screen and(min-width:768px) {
            a{
                display: inline-block;
                width: calc(#{$tree_link--md} - #{$tree__link--gutter*5});
            }

        }
        @media screen and(min-width:1440px) {
            a{
                width: calc(#{$tree_link--xxl} - #{$tree__link--gutter+100px});
            }
        }
        .circle{
            left:-72px;
        }
    }
    .search-result--tree__circle-8{
        @media screen and(min-width:768px) {
            a{
                display: inline-block;
                width: calc(#{$tree_link--md} - #{$tree__link--gutter*6});
            }
        }
        @media screen and(min-width:1440px) {
            a{
                width: calc(#{$tree_link--xxl} - #{$tree__link--gutter+120px});
            }
        }

        .circle{
            left:-80px;
        }
    }
    .search-result--tree__circle-9{
        @media screen and(min-width:768px) {
            a{
                display: inline-block;
                width: calc(#{$tree_link--md} - #{$tree__link--gutter*7});
            }
        }
        @media screen and(min-width:1440px) {
            a{
                width: calc(#{$tree_link--xxl} - #{$tree__link--gutter+140px});
            }
        }

        .circle{
            left:-88px;
        }
    }
    .search-result--tree__circle-10{
        @media screen and(min-width:768px) {
            a{
                display: inline-block;
                width: calc(#{$tree_link--md} - #{$tree__link--gutter*8});
            }
        }
        @media screen and(min-width:1440px) {
            a{
                width: calc(#{$tree_link--xxl} - #{$tree__link--gutter+160px});
            }
        }
        .circle{
            left:-96px;
        }
    }
    .search-result--tree__circle-11{
        @media screen and(min-width:768px) {
            a{
                display: inline-block;
                width: calc(#{$tree_link--md} - #{$tree__link--gutter*9});
            }
        }
        @media screen and(min-width:1440px) {
            a{
                width: calc(#{$tree_link--xxl} - #{$tree__link--gutter+180px});
            }
        }
        .circle{
            left:-104px;
        }
    }
    .result-stats{
        font-size: 1rem;
    }
}

.search-results--tree-lines .search-result--tree__circle:nth-of-type(odd) .search-result--tree__inner-wrapper
{
    background-color:rgba(0,0,0,.05);
}
.search-results--tree-lines  .search-result--tree__circle:nth-of-type(even) .search-result--tree__inner-wrapper{

    background-color: white;
}


.treefields-dropdown{
    float: right;
    top:0;
    .btn-sm{
    padding: .125rem .25rem;
    font-size: .8125rem;
    line-height: 1;
    border-radius: .2rem;    
    }
    
    .dropdown-menu{
        min-width: 320px;
        label{
            margin: 0.5em 0.2em 0 0
        }
    }    
}
.search-result--tree__fields{
    display: none;
    padding: 0 15px;
    vertical-align: middle;
    font-size: 1em;
    width: 33%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 24px;    
    
    div{
        display: inline-block;
        padding-right: 0.75em;
    }
    div:last-child{
        padding-right: 0;
    }
    @media screen and (min-width:768px) {
        display: inline-block;        
    }
    @media screen and (min-width:1440px) {
        width: 50%;        
    }
}
.search-result--tree__entitytypename{
    width: auto !important;    
    color:#007ec5;
    padding-left:0;
}

/* ID-27 */
.search-result--tree__organizationallevelname {
    color: #007ec5;
}

.search-result--tree__postaladdress{
    width: auto !important;    
    color:#385c6b;
    padding-left:0 !important;
}
.search-result--tree__specialityname{
    width: auto !important;    
    padding-left:0 !important;
    color: #bfe600;
}
.search-result--tree__cvrid{
    width: auto !important;    
    padding-left:0 !important;
    color:#53a71c
}
.search-result--tree__sordid{
    width: auto !important;    
    padding-left:0 !important;
    color: #ffd400;
}
.search-result--tree__shakid{
    width: auto !important;    
    padding-left:0 !important;
    color:#abbfcc;
}
.search-result--tree__eanlocationcode{
    width: auto !important;    
    padding-left:0 !important;
    color:#6e9db8;
}
.search-result--tree__providerid{
    width: auto !important;    
    padding-left:0 !important;
    color:#888888;
}
.search-result--tree__pharmacyId{
    width: auto !important;    
    padding-left:0 !important;
    color:#ff9c00;
}

.build-menu{
    .pool{
        border:1px solid rgba(0,0,0,.125);
    }
    .droppable{
        border:1px solid rgba(0,0,0,.125);        
    }
    .draggable{
        background-color:#405b6b;
        display: block;
        color:white;
        margin: 0.5em;
    }
    .task-header{
        font-weight: bold;
    }
}
.build-menu__toggle{
    color: white;
    background-color:#405b6b;
    &:hover{
        background-color: black;
    }

}

.with-font-awesome-icon {
    position: absolute;
    opacity: 0;
}
